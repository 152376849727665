const DevicesView = require('./main/devices.js');
const VideoView = require('./main/video-view.js');
const AdminViews = require('./admin/admin-views.js');

document.addEventListener('DOMContentLoaded', () => {
    DevicesView.init('#devices-page');
    VideoView.init('#device-video-page');
    AdminViews.init('#admin');
  }
);
